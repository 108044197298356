import React, { useState } from 'react';
import { getAdminWebClient } from '../../helpers/Helpers.js';

export default function AdminLogin({ setAdminAuthenticationToken }) {

    const wiggletime = 150;

    function handleLoginClick() {

        const pin = document.getElementById('txtAdminLoginPin').value;
        const client = getAdminWebClient();

        client.get(`/api/admin/gettokenadmin/${pin}`)
            .then(function (response) {
                if (response.data.token) {
                    localStorage.setItem("adminAuthenticationToken", response.data.token);
                    setAdminAuthenticationToken(response.data.token);
                } else {
                    document.getElementById('txtAdminLoginPin').value = "";
                    shakeElement(document.getElementById('txtAdminLoginPin'));
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function shakeElement(el) {
        el.classList.add('rotateable');
        el.style.marginLeft = '15px';

        setTimeout(function () {
            el.style.marginLeft = '-15px';
            setTimeout(function () {
                el.style.marginLeft = '0px';
            }, wiggletime);
        }, wiggletime);

        return true;
    }

    return (
        <>

            <div className="container vh-100" id="divFormContainer">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h3 className="card-title text-center mb-4">SIHE - Area amministrativa</h3>

                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">
                                        Pin
                                    </label>
                                    <input
                                        id="txtAdminLoginPin"
                                        type="password"
                                        className="form-control"
                                        placeholder="Inserisci il pin per accedere"
                                    />
                                </div>
                                <button id="btnLoginAdminSignIn" className="btn btn-primary w-100" onClick={handleLoginClick}>
                                    Login
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
