import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PartnerDateTime from './components/PartnerDateTime';
import PromptConfirmAppointment from './components/PromptConfirmAppointment';
import PromptConfirmAppointmentQueue from './components/PromptConfirmAppointmentQueue';
import PromptMailSent from './components/PromptMailSent';
import PromptError from './components/PromptError';

export default function PartnerAgenda() {

    const { partnerid } = useParams();
    const [viewMode, setViewMode] = useState('waiting');
    const [profile, setprofile] = useState({ company: '' });
    const [agenda, setagenda] = useState([]);
    const [days, setDays] = useState([{ label: '1ST NOVEMBER', id: 1, enabled: true }, { label: '2ND NOVEMBER', id: 2, enabled: true }, { label: '3RD NOVEMBER', id: 3, enabled: true }]);
    const [day, setDay] = useState(0);
    const [dateTimeToBookId, setDateTimeToBookId] = useState([]);
    const [dateTimeToBookQueueId, setDateTimeToBookQueueId] = useState([]);
    const navigate = useNavigate();
    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/getpartnerenableddates/${partnerid}`)
            .then(function (response) {

                setDays(response.data.filter(obj => {
                    return obj.enabled === true
                }));

                client.get(`/api/getpartnerprofile/${partnerid}`)
                    .then(function (response) {
                        setprofile(response.data);
                        fetchData(days[0].id);
                    })
                    .catch(function (error) {
                        console.log(error);
                        setViewMode('error');
                    });

            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }, []);


    const fetchData = async (dayId) => {

        client.get(`/api/getpartneragenda/${partnerid}/${dayId}`)
            .then(function (response) {
                setagenda(response.data);
                setViewMode('');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    };   

    function handleAppointmentCreateClick(dateTimeId) {
        setDateTimeToBookId(dateTimeId);
        setViewMode('appointmentcreate');
    }

    function doAppointmentCreate() {

        setViewMode('waiting');

        client.get(`/api/sendappointmentrequest/${partnerid}/${dateTimeToBookId}`)
            .then(function (response) {
                setViewMode('mailsent');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }

    function handleAppointmentQueueCreateClick(dateTimeId) {
        setDateTimeToBookQueueId(dateTimeId);
        setViewMode('appointmentqueuecreate');
    }

    function doAppointmentQueueCreate() {

        setViewMode('waiting');

        client.get(`/api/sendappointmentrequestqueue/${partnerid}/${dateTimeToBookQueueId}`)
            .then(function (response) {
                setViewMode('mailsent');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }

    function handlePreviousDayClick(appointmentid) {
        if (day > 0) {
            let newday = day - 1;
            setDay(newday);
            fetchData(days[newday].id);
        }
    }

    function handleNextDayClick(appointmentid) {
        if (day < days.length - 1) {
            let newday = day + 1;
            setDay(newday);
            fetchData(days[newday].id);
        }
    }

    const partnerDateTimes = agenda.map((dateTime, index) => {
        return (<PartnerDateTime dateTime={dateTime} handleAppointmentCreateClick={handleAppointmentCreateClick} handleAppointmentQueueCreateClick={handleAppointmentQueueCreateClick} />)
    });

    return (
        <>

            <section className="buyer-sec ca-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="/assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>Switzerland, 1/3 November 2024</p>
                        </div>
                    </div>
                </header>
                <div className="container  ca-container">
                    <h1 className="main-heading">Company Agenda</h1>
                    <p>Here is the agenda of ‘{profile.company}’, ask for an appointment!</p>
                    <div className="row">
                        <h4>Description</h4>
                        <ul>
                            <li className=""> <img src="/assets/images/icon.svg" alt="" /> <span> The appointment is available </span></li>
                            <li className=""> <img src="/assets/images/icon2.svg" alt="" /> <span> The appointment is already booked, but you can put yourself in queue </span></li>
                            <li className=""> <img src="/assets/images/icon3.svg" alt="" /> <span> If the appointment is already booked click here to put yourself on queue </span></li>
                            <li className=""> <img src="/assets/images/icon4.svg" alt="" /> <span> The appointment is free but you are already busy at this time  </span></li>
                            <li className=""> <img src="/assets/images/icon5.svg" alt="" /> <span> The queue is free but you are already busy at this time</span></li>
                            <li className=""> <img src="/assets/images/icon6.svg" alt="" /> <span> The appointment or the queue are busy fot both; chose another slot </span></li>
                        </ul>
                        <div className="col-md-12 text-center">
                            <div className="btn btn-primary main-btn button-fullwidth">
                                <img src="/assets/images/iconLeft.svg" id="leftArrow" alt="" onClick={handlePreviousDayClick} />
                                <button className="btn btn-primary" id="appointmentButton">{days[day].label} appointments</button>
                                <img src="/assets/images/iconRight.svg" id="rightArrow" alt="" onClick={handleNextDayClick} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="table search-table table-striped ca-table">
                                <thead>
                                    <tr>
                                        <th className="ps-2">Time</th>
                                        <th className="ps-2">Available</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partnerDateTimes}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptConfirmAppointment show={viewMode === 'appointmentcreate'} setViewMode={setViewMode} doAppointmentCreate={doAppointmentCreate}></PromptConfirmAppointment>
            <PromptConfirmAppointmentQueue show={viewMode === 'appointmentqueuecreate'} setViewMode={setViewMode} doAppointmentQueueCreate={doAppointmentQueueCreate}></PromptConfirmAppointmentQueue>
            <PromptMailSent show={viewMode === 'mailsent'} setViewMode={setViewMode}></PromptMailSent>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>

        </>
    );

}