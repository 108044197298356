import React from 'react';

export default function PartnerDateTime({ dateTime, handleAppointmentCreateClick, handleAppointmentQueueCreateClick }) {

    if (dateTime.abilitato) {

        let className = 'free';
        let status = 'Free';
        let appointmentButton = (<img src="/assets/images/icon.svg" alt="" onClick={() => handleAppointmentCreateClick(dateTime.id)} />);
        let queueButton = (<img src="/assets/images/icon3.svg" alt="" onClick={() => handleAppointmentQueueCreateClick(dateTime.id)} />);

        if (dateTime.partner) { appointmentButton = (<img src="/assets/images/icon2.svg" alt="" />); className = null; status = 'Booked'; }
        if (dateTime.queuedPartner) { queueButton = (<img src="/assets/images/icon6.svg" alt="" />); }

        if (!dateTime.partner && dateTime.viewer) { appointmentButton = (<img src="/assets/images/icon4.svg" alt="" />); }
        if (!dateTime.queuedPartner && dateTime.queuedViewer) { queueButton = (<img src="/assets/images/icon5.svg" alt="" />); }

        return (
            <tr className={className}>
                <td>{dateTime.oraDa} - {dateTime.oraA}</td>
                <td className="ps-2">{status}</td>
                <td className="table-img">
                    {appointmentButton}
                    {queueButton}
                </td>
            </tr>
        );

    }
    else
    {

        return (
            <tr>
                <td>{dateTime.oraDa} - {dateTime.oraA}</td>
                <td className="ps-2">Not available</td>
                <td className="table-img">
                    <img src="/assets/images/icon6.svg" alt="" />
                    <img src="/assets/images/icon6.svg" alt="" />
                </td>
            </tr>
        );

    }


}
