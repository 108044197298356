import React from 'react';

export default function DateTimeQueue({ dateTime, handleAppointmentQueueDeleteClick }) {

    if (!dateTime.queuedPartner) {

        return (
            <tr>
                <td className="w-260">None is queuing</td>
                <td className="table-img">
                    <a href="/search"><img src="assets/images/icon3.svg" alt="" /></a>
                </td>
            </tr>
        );

    } else {

        return (
            <tr>
                <td className="w-260">Queuing with <b>{dateTime.queuedPartner.ragioneSociale}</b></td>
                <td className="table-img">
                    <div onClick={() => handleAppointmentQueueDeleteClick(dateTime.queuedAppointmentGuid)}><img src="assets/images/icon8.svg" alt="" /></div>
                </td>
            </tr>
        );
    }
}
