import React from 'react';

export default function PromptAuthenticationError({ show, setViewMode }) {

    if (show) {

        return (
            <div className="modal fade show" id="updateSuccessfulModal" aria-modal="true" role="dialog" style={{ display: "block" }} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h2>Update successful</h2>
                            <p>Data has been successfully updated</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => setViewMode('')}> Ok </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else { return null; }

}
