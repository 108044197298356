import React from 'react';
import Navbar from './components/Navbar'

export default function AdminDashboard({ setAdminAuthenticationToken }) {

    return (
        <>

            <Navbar setAdminAuthenticationToken={setAdminAuthenticationToken} />

        </>
    )
}
