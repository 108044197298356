import React from 'react';
import DateTimeAppointment from './DateTimeAppointment'
import DateTimeQueue from './DateTimeQueue'

export default function DateTime({ dateTime, handleAppointmentDeleteClick, handleAppointmentQueueDeleteClick, handleDoAppointmentDelete }) {

    if (dateTime.abilitato) {

        return (
            <table className="table ma-table table-nomargin">
                <tbody>
                    <DateTimeAppointment dateTime={dateTime} handleAppointmentDeleteClick={handleAppointmentDeleteClick} handleDoAppointmentDelete={handleDoAppointmentDelete} />
                    <DateTimeQueue dateTime={dateTime} handleAppointmentQueueDeleteClick={handleAppointmentQueueDeleteClick} />
                </tbody>
            </table>
        );

    } else {

        return (

            <table className="table ma-table table-nomargin">
                <tbody>
                    <tr>
                        <td rowSpan={2} className="table-btns">
                            10:30 - 10:55
                        </td>
                        <td className="ps-2 w-260">
                            You are not enabled for this date/time
                        </td>
                        <td className="table-img" style={{ cursor: 'default' }}>
                            <div>
                                <img src="assets/images/icon6.svg" alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="w-260">
                            Your queue is not enabled for this date/time
                        </td>
                        <td className="table-img" style={{ cursor: 'default' }}>
                            <div>
                                <img src="assets/images/icon6.svg" alt="" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        );

    }


}
