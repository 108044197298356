import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError';
import ActivityRow from './components/ActivityRow'

export default function Activity() {

    const [viewMode, setViewMode] = useState('waiting');
    const [activities, setActivities] = useState();
    const navigate = useNavigate();
    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/getactivities`)
            .then(function (response) {

                setActivities(response.data);
                setViewMode('');

            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }, []);

    let activitiesRows;

    if (activities) {

        activitiesRows = activities.map((activity, index) => {
            return (
                <ActivityRow activity={activity} index={index}></ActivityRow>
            );
        });

    }

    return (

        <>

            <section className="buyer-sec search-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>Switzerland, 1/3 November 2024</p>
                        </div>
                    </div>
                </header>
                <div className="buyer-container search-container">
                    <h1 className="main-heading">Your activity</h1>
                    <p>Here you can find all the appointment requests you have sent and their status.</p>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table search-table">
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Date/time</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activitiesRows}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>


        </>
    );


}