import React from 'react';

export default function Spinner({ show }) {

    if (show) {

        return (
            <div className="spinner"></div>
        );

    } else { return null; }
}
