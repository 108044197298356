import React from 'react';

export default function PromptConfirmDelete({ show, setViewMode, doAppointmentDelete }) {

    if (show) {

        return (
            <div className="modal fade show" id="confirmDeleteModal" aria-modal="true" role="dialog" style={{ display: "block" }} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h2>Proceed with the appointment cancellation?</h2>
                            <p>This action cannot be undone</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" onClick={() => setViewMode('')}> No </button>
                            <button type="button" className="btn btn-primary" onClick={() => doAppointmentDelete()}> Yes </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else { return null; }

}