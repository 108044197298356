import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { getAdminWebClient } from '../../helpers/Helpers.js';

export default function AdminAnagrafiche({ setAdminAuthenticationToken }) {

    const [viewMode, setViewMode] = useState('waiting');
    const [profiles, setProfiles] = useState();
    const [showBuyer, setShowBuyer] = useState(true);
    const [showSeller, setShowSeller] = useState(true);
    const client = getAdminWebClient();

    useEffect(() => {

        client.get(`/api/admin/managecompanydata`)
            .then(function (profilesResponse) {
                setProfiles(profilesResponse.data);
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error')
            });

    }, []);

    let profilesRows;

    if (profiles) {

        profilesRows = profiles.map((profile, index) => {

            if ((profile.seller && showSeller) || (!profile.seller && showBuyer) ) {
                return (
                    <tr key={index}>
                        <td><a href={'/admin/anagrafiche/edit/' + profile.guid}>{profile.ragioneSociale}</a></td>
                        <td>{profile.localita}</td>
                        <td>{profile.nazione}</td>
                        <td>{profile.seller ? 'Seller' : 'Buyer'}</td>
                    </tr>
                );
            } else {
                return '';
            }

        });

    };

    return (
        <>

            <Navbar setAdminAuthenticationToken={setAdminAuthenticationToken} />

            <div className="container" style={{ marginTop: 20, marginBottom: 20 }} >
                <div className="row">
                    <div className="col">
                        <h4>Anagrafiche</h4>
                    </div>
                    <div className="col" style={{ textAlign: "right" }}>                       
                        <button type="button" className="btn btn-primary" onClick={() => window.location.assign("/admin/anagrafiche/add")}>Aggiungi nuova</button><br></br>
                    </div>
                </div>   
                <div className="row">
                    <div className="col">
                        Visualizza:&nbsp;
                        Buyer <input type="checkbox" id="chkShowBuyer" checked={showBuyer} onChange={() => setShowBuyer(!showBuyer)}></input>&nbsp;&nbsp;
                        Seller <input type="checkbox" id="chkShowSeller" checked={showSeller} onChange={() => setShowSeller(!showSeller)}></input>
                    </div>
                </div>  
            </div>

            <div className="container">

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Ragione sociale</th>
                            <th scope="col">Localita</th>
                            <th scope="col">Nazione</th>
                            <th scope="col">Buyer/Seller</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profilesRows}
                    </tbody>
                </table>

            </div>

        </>
    )
}
