import React from 'react';

export default function DateTimeAppointment({ dateTime, handleAppointmentDeleteClick }) {

    if (!dateTime.partner) {

        return (
            <tr>
                <td rowSpan="2" className="table-btns">{dateTime.oraDa} - {dateTime.oraA}</td>
                <td className="ps-2 w-260" >The appointment is free</td>
                <td className="table-img">
                    <a href="/search"><img src="assets/images/icon3.svg" alt="" /></a>
                </td>
            </tr>
        );

    } else {

        return (
            <tr>
                <td rowSpan="2" className="table-btns">{dateTime.oraDa} - {dateTime.oraA}</td>
                <td className="ps-2 w-260" >Appointment with <b> {dateTime.partner.ragioneSociale} </b></td>
                <td className="table-img">
                    <div onClick={() => handleAppointmentDeleteClick(dateTime.appointmentGuid) }><img src="assets/images/icon8.svg" alt="" /></div>
                </td>
            </tr>
        );
    }
}
