import React from 'react';

export default function TipologiaDropdown({ IsSeller }) {

    if (IsSeller) {

        return (

            <div className="row mb-3">
                <label htmlFor="selIdTipologia" className="col-sm-2 col-form-label">
                    Id Tipologia
                </label>
                <div className="col-sm-10">
                    <select className="form-select" id="selIdTipologia">
                        <option value="22">Seller</option>
                        <option value="24">Accomodation</option>
                        <option value="15">Convention Bureau</option>
                        <option value="10">Hotel Chain 5 Stars With MICE Facilities</option>
                        <option value="14">Hotels And Chains With MICE Facilities</option>
                        <option value="18">Hotel</option>
                        <option value="19">Hotel 5 Stars - Hotel Chain</option>
                        <option value="16">Location For Events</option>
                        <option value="12">Resorts</option>
                        <option value="25">Sail Charter</option>
                        <option value="17">Services, Transport, Catering</option>
                        <option value="13">Tourism Board - Dmc</option>
                        <option value="11">Villas And Historical Venue</option>
                    </select>
                </div>
            </div>

        );

    } else {

        return (

            <div className="row mb-3">
                <label htmlFor="selIdTipologia" className="col-sm-2 col-form-label">
                    Id Tipologia
                </label>
                <div className="col-sm-10">
                    <select className="form-select" id="selIdTipologia">
                        <option value="23">Buyer</option>
                        <option value="3">Corporate Decision Manager</option>
                        <option value="4">Federation - Association</option>
                        <option value="5">Incentive Travel Organizer Marketing Agencies</option>
                        <option value="6">Luxury Tour Operator</option>
                        <option value="7">Personal Travel Arranger Retail Travel Agent</option>
                        <option value="1">Tour Operator - Business Travel Agency</option>
                        <option value="2">Wedding Planner - Event Manager, Pco</option>
                    </select>
                </div>
            </div>

        );

    }

}
